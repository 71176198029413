import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import DefaultClient from "apollo-boost"
import createShopifyClient from "@shopify/createApolloClient"
import { GetCustomer } from "@shopify/graphql/queries"
import {
  CreateAccount,
  RecoverAccount,
  ResetAccount,
  ResetAccountByUrl,
  GetCustomerAccessToken,
  DeleteCustomerAccessToken,
  AssociateCheckout,
  CustomerAddressUpdate,
  CustomerAddressCreate,
  CustomerAddressDelete,
  CustomerDefaultAddressUpdate,
} from "@shopify/graphql/mutations"
import { LineItem, CustomerData } from "@shopify/types"
import createContentfulClient from "@cms/createApolloClient"
import { GetCustomerPurchasedProductsData } from "@cms/graphql/queries"
import { ContentfulPurchasedProduct } from "@cms/types"
import {
  CustomerSignup,
  CustomerReset,
  CustomerLogin,
  PurchasedProducts,
} from "../types"
import {
  graphQLRequest,
  UNKNOWN_LOCATION,
  fetchCustomerLocation,
  buildPurchasedProducts,
} from "../utils"
import { getIn, isNilOrEmpty } from "@utils/index"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import {
  CUSTOMER_KEY,
  CUSTOMER_ZIP_CODE_KEY,
  CUSTOMER_STATE_CODE_KEY,
  CUSTOMER_COUNTRY_CODE_KEY,
} from "@constants/storageKeys"
import * as R from "ramda"

export interface CustomerState {
  customerSignedUp: boolean
  customerLoggedIn: boolean
  customerAccessToken: string | null
  customerDataFetched: boolean
  data: CustomerData
  customerLocationFetched: boolean
  customerLocation: {
    customerZipCode: string
    customerStateCode: string
    customerCountryCode: string
  }
  purchasedProducts: PurchasedProducts
}

const customer = createModel<RootModel>()({
  name: "customer",
  state: {
    customerSignedUp: false,
    customerLoggedIn: false,
    customerAccessToken: getLocalStorage(CUSTOMER_KEY),
    customerDataFetched: false, // Fetch Customer data once
    data: {} as CustomerData,
    customerLocationFetched: false,
    customerLocation: {
      customerZipCode:
        getLocalStorage(CUSTOMER_ZIP_CODE_KEY) || UNKNOWN_LOCATION,
      customerStateCode:
        getLocalStorage(CUSTOMER_STATE_CODE_KEY) || UNKNOWN_LOCATION,
      customerCountryCode:
        getLocalStorage(CUSTOMER_COUNTRY_CODE_KEY) || UNKNOWN_LOCATION,
    },
    purchasedProducts: {},
  } as CustomerState,
  reducers: {
    setCustomerSignedUp(state: CustomerState, customerSignedUp: boolean) {
      return {
        ...state,
        customerSignedUp,
      }
    },

    setCustomerLoggedIn(state: CustomerState, customerLoggedIn: boolean) {
      return {
        ...state,
        customerLoggedIn,
      }
    },

    setCustomerAccessToken(state: CustomerState, customerAccessToken: string) {
      return {
        ...state,
        customerAccessToken,
      }
    },

    setCustomerDataFetched(state: CustomerState, customerDataFetched: boolean) {
      return {
        ...state,
        customerDataFetched,
      }
    },

    updateCustomerData(state: CustomerState, data: CustomerData) {
      return {
        ...state,
        data,
      }
    },

    removeCustomerData(state: CustomerState) {
      return {
        ...state,
        data: {} as CustomerData,
      }
    },

    setCustomerLocationFetched(
      state: CustomerState,
      customerLocationFetched: boolean
    ) {
      return {
        ...state,
        customerLocationFetched,
      }
    },

    setCustomerLocation(
      state: CustomerState,
      customerLocation: {
        customerZipCode: string
        customerStateCode: string
        customerCountryCode: string
      }
    ) {
      return {
        ...state,
        customerLocation,
      }
    },

    updatePurchasedProducts(
      state: CustomerState,
      data: Array<ContentfulPurchasedProduct>
    ) {
      return {
        ...state,
        purchasedProducts: {
          ...state.purchasedProducts,
          ...buildPurchasedProducts(data),
        },
      }
    },
  },
  effects: (dispatch: any) => ({
    updateCustomerAccessToken(customerAccessToken) {
      writeLocalStorage(CUSTOMER_KEY, customerAccessToken)
      dispatch.customer.setCustomerAccessToken(customerAccessToken)
    },

    removeCustomerAccessToken() {
      writeLocalStorage(CUSTOMER_KEY, null)
      dispatch.customer.setCustomerAccessToken(null)
    },

    updateCustomerLocation(customerLocation) {
      writeLocalStorage(CUSTOMER_ZIP_CODE_KEY, customerLocation.customerZipCode)
      writeLocalStorage(
        CUSTOMER_STATE_CODE_KEY,
        customerLocation.customerStateCode
      )
      writeLocalStorage(
        CUSTOMER_COUNTRY_CODE_KEY,
        customerLocation.customerCountryCode
      )
      dispatch.customer.setCustomerLocation(customerLocation)
      dispatch.customer.setCustomerLocationFetched(true)
    },

    updateCustomerZipCode(customerZipCode, rootState) {
      writeLocalStorage(CUSTOMER_ZIP_CODE_KEY, customerZipCode)
      dispatch.customer.setCustomerLocation({
        customerZipCode,
        customerCountryCode:
          rootState.customer.customerLocation.customerCountryCode ||
          UNKNOWN_LOCATION,
      })
    },

    async createAccount({
      input,
      callback,
    }: {
      input: CustomerSignup
      callback: any
    }) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: CreateAccount,
        variables: { input },
      })

      if (errors) {
        return {
          success: false,
          error: errors.message,
        }
      }

      const customerData = getIn(data, "customerCreate.customer", null)
      const userErrors = getIn(data, "customerCreate.customerUserErrors", null)

      if (customerData) {
        const id = getIn(customerData, "id", "")
        const cleanedId = id.replace("gid://shopify/Customer/", "")
        const email = getIn(customerData, "email", "")

        if (callback) {
          callback({ id: cleanedId, email })
        }

        dispatch.customer.setCustomerSignedUp(true)
        dispatch.customer.updateCustomerData(customerData)
        dispatch.customer.loginCustomer({
          email: input.email,
          password: input.password,
        })
        return { success: true, error: "" }
      } else if (userErrors && userErrors.length > 0) {
        return { success: false, error: userErrors[0].message }
      } else {
        return {
          success: false,
          error: "We encounterd an issue. Please try again.",
        }
      }
    },

    async recoverAccount(email: string) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: RecoverAccount,
        variables: { email },
      })

      if (errors) {
        return {
          success: false,
          error: errors.message,
        }
      }

      const userErrors = getIn(data, "customerRecover.customerUserErrors", null)

      if (userErrors && userErrors.length > 0) {
        return { success: false, error: userErrors[0].message }
      } else {
        return {
          success: true,
          error: "",
        }
      }
    },

    async resetAccount(
      { id, input }: { id: string; input: CustomerReset },
      rootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: ResetAccount,
        variables: { id, input },
      })

      if (errors) {
        return {
          success: false,
          error: errors.message,
        }
      }

      const userErrors = getIn(data, "customerReset.customerUserErrors", [])

      if (userErrors && userErrors.length > 0) {
        return { success: false, error: userErrors[0].message }
      }

      const customerData = getIn(data, "customerReset.customer")
      const customerAccessToken = getIn(
        data,
        "customerReset.customerAccessToken.accessToken",
        null
      )

      if (customerData && customerAccessToken) {
        const customerCheckout = getIn(
          customerData,
          "lastIncompleteCheckout",
          null
        )

        if (
          !rootState.checkout.checkoutId &&
          customerCheckout &&
          customerCheckout.id
        ) {
          const lineItems = getIn(customerCheckout, "lineItems.edges", [])
          const invalidLineItem =
            !R.isEmpty(lineItems) &&
            R.find((item: LineItem) =>
              isNilOrEmpty(getIn(item, "node.variant"))
            )(customerCheckout.lineItems.edges)
          const customerCheckoutIsValid =
            customerCheckout &&
            !customerCheckout.completedAt &&
            !invalidLineItem

          if (customerCheckoutIsValid) {
            dispatch.checkout.fetchCheckout(customerCheckout.id)
          }
        } else if (rootState.checkout.checkoutId) {
          dispatch.customer.associateCheckoutId(rootState.checkout.checkoutId)
        }

        dispatch.customer.updateCustomerAccessToken(customerAccessToken)
        dispatch.customer.updateCustomerData(customerData)
        dispatch.customer.setCustomerDataFetched(true)
        return { success: true, error: "" }
      } else {
        dispatch.customer.removeCustomerAccessToken()
        dispatch.customer.removeCustomerData()
        dispatch.customer.setCustomerDataFetched(true)
        return { success: false, error: "Something went wrong." }
      }
    },

    async resetAccountByUrl(
      { password, resetUrl }: { password: string; resetUrl: string },
      rootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: ResetAccountByUrl,
        variables: { password, resetUrl },
      })

      if (errors) {
        return {
          success: false,
          error: errors.message,
        }
      }

      const userErrors = getIn(
        data,
        "customerResetByUrl.customerUserErrors",
        []
      )

      if (userErrors && userErrors.length > 0) {
        return { success: false, error: userErrors[0].message }
      }

      const customerData = getIn(data, "customerResetByUrl.customer")
      const customerAccessToken = getIn(
        data,
        "customerResetByUrl.customerAccessToken.accessToken",
        null
      )

      if (customerData && customerAccessToken) {
        const customerCheckout = getIn(
          customerData,
          "lastIncompleteCheckout",
          null
        )

        if (
          !rootState.checkout.checkoutId &&
          customerCheckout &&
          customerCheckout.id
        ) {
          const lineItems = getIn(customerCheckout, "lineItems.edges", [])
          const invalidLineItem =
            !R.isEmpty(lineItems) &&
            R.find((item: LineItem) =>
              isNilOrEmpty(getIn(item, "node.variant"))
            )(customerCheckout.lineItems.edges)
          const customerCheckoutIsValid =
            customerCheckout &&
            !customerCheckout.completedAt &&
            !invalidLineItem

          if (customerCheckoutIsValid) {
            dispatch.checkout.fetchCheckout(customerCheckout.id)
          }
        } else if (rootState.checkout.checkoutId) {
          dispatch.customer.associateCheckoutId(rootState.checkout.checkoutId)
        }

        dispatch.customer.updateCustomerAccessToken(customerAccessToken)
        dispatch.customer.updateCustomerData(customerData)
        dispatch.customer.setCustomerDataFetched(true)
        return { success: true, error: "" }
      } else {
        dispatch.customer.removeCustomerAccessToken()
        dispatch.customer.removeCustomerData()
        dispatch.customer.setCustomerDataFetched(true)
        return { success: false, error: "Something went wrong." }
      }
    },

    async loginCustomer(
      { input, callback }: { input: CustomerLogin; callback: any },
      rootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: GetCustomerAccessToken,
        variables: { input },
      })

      const customerAccessToken = getIn(
        data,
        "customerAccessTokenCreate.customerAccessToken.accessToken",
        null
      )

      const loginErrors = getIn(
        data,
        "customerAccessTokenCreate.userErrors",
        []
      )

      if (customerAccessToken) {
        dispatch.customer.setCustomerLoggedIn(true)
        dispatch.customer.updateCustomerAccessToken(customerAccessToken)
        dispatch.customer.fetchCustomerData({ customerAccessToken, callback })
        if (rootState.checkout.checkoutId) {
          dispatch.customer.associateCheckoutId(rootState.checkout.checkoutId)
        }
        return { success: true, error: "" }
      } else if (errors) {
        return {
          success: false,
          error: "We encountered an issue. Please try again.",
        }
      } else if (loginErrors && loginErrors.length > 0) {
        return { success: false, error: "Incorrect email or password." }
      }
      return { success: false, error: "Something went wrong." }
    },

    async logoutCustomer(customerAccessToken) {
      const client: DefaultClient<any> = createShopifyClient()
      const result = await graphQLRequest(client.mutate, {
        mutation: DeleteCustomerAccessToken,
        variables: { customerAccessToken },
      })

      dispatch.customer.removeCustomerAccessToken()
      dispatch.customer.removeCustomerData()
      return result
    },

    async fetchCustomerData({ customerAccessToken, callback }, rootState) {
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetCustomer,
          variables: { customerAccessToken },
        })

        const customerData = getIn(data, "customer")

        if (customerData) {
          const id = getIn(customerData, "id", "")
          const cleanedId = id.replace("gid://shopify/Customer/", "")
          const email = getIn(customerData, "email", "")

          if (callback) {
            callback({ id: cleanedId, email })
          }

          const customerCheckout = getIn(
            customerData,
            "lastIncompleteCheckout",
            null
          )

          if (
            !rootState.checkout.checkoutId &&
            customerCheckout &&
            customerCheckout.id
          ) {
            const lineItems = getIn(customerCheckout, "lineItems.edges", [])
            const invalidLineItem =
              !R.isEmpty(lineItems) &&
              R.find((item: LineItem) =>
                isNilOrEmpty(getIn(item, "node.variant"))
              )(customerCheckout.lineItems.edges)
            const customerCheckoutIsValid =
              customerCheckout &&
              !customerCheckout.completedAt &&
              !invalidLineItem

            if (customerCheckoutIsValid) {
              dispatch.checkout.fetchCheckout(customerCheckout.id)
            }
          }

          dispatch.customer.updateCustomerAccessToken(customerAccessToken)
          dispatch.customer.updateCustomerData(customerData)
          dispatch.customer.setCustomerDataFetched(true)
          return true
        } else {
          dispatch.customer.removeCustomerAccessToken()
          dispatch.customer.removeCustomerData()
          dispatch.customer.setCustomerDataFetched(true)
        }
      } else {
        dispatch.customer.setCustomerDataFetched(true)
      }
      return false
    },

    async fetchCustomerLocation() {
      const customerLocation = await fetchCustomerLocation()
      dispatch.customer.updateCustomerLocation(customerLocation)
      return customerLocation
    },

    associateCheckoutId(checkoutId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        return graphQLRequest(client.mutate, {
          mutation: AssociateCheckout,
          variables: {
            customerAccessToken,
            checkoutId,
          },
        })
      }
      return undefined
    },

    async addressCreate({ setDefault, ...address }, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressCreate,
          variables: {
            customerAccessToken,
            address,
          },
        })

        dispatch.customer.fetchCustomerData({ customerAccessToken })

        const defaultAddress = rootState.customer.data.defaultAddress

        if (!defaultAddress || setDefault) {
          const createdId = getIn(
            result,
            "data.customerAddressCreate.customerAddress.id",
            ""
          )
          dispatch.customer.updateDefaultAddress(createdId)
        }

        return result
      }
      return undefined
    },

    async addressUpdate({ id, setDefault, ...address }, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressUpdate,
          variables: {
            id,
            customerAccessToken,
            address,
          },
        })
        dispatch.customer.fetchCustomerData({ customerAccessToken })

        const defaultAddress = rootState.customer.data.defaultAddress

        if (!defaultAddress || (setDefault && defaultAddress.id !== id)) {
          dispatch.customer.updateDefaultAddress(id)
        }

        return result
      }
      return undefined
    },

    async addressDelete(addressId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressDelete,
          variables: {
            id: addressId,
            customerAccessToken,
          },
        })

        dispatch.customer.fetchCustomerData({ customerAccessToken })

        return result
      }
      return undefined
    },

    async updateDefaultAddress(addressId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerDefaultAddressUpdate,
          variables: {
            addressId,
            customerAccessToken,
          },
        })

        dispatch.customer.fetchCustomerData({ customerAccessToken })

        return result
      }
      return undefined
    },

    async fetchPurchasedProducts({ productIds }) {
      const client: DefaultClient<any> = createContentfulClient()
      let productData = []

      if (productIds) {
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetCustomerPurchasedProductsData,
          variables: { ids: productIds },
        })

        productData = getIn(data, "productVariantCollection.items", [])
      }

      dispatch.customer.updatePurchasedProducts(productData)
    },
  }),
})

export default customer
